import React from "react";
import { Divider, Image, Row, Col } from 'antd';
import './index.less';
import pic from './img/1.jpg';
import pic2 from './img/2.jpg';
import pic3 from './img/3.jpg';
import pic4 from './img/4.jpg';

export default class Factory extends React.Component{
    render () {
        return (
            <div className="factory" >
                <Divider children={'Our Factory'}  className='line-title' id='factory_pc'/>
                <Row>
                    <Col span={6} style={{ padding: '8px' }}>
                        <Image src={pic} width={'100%'} preview={false} />
                        <Image src={pic2} width={'100%'} preview={false} />
                    </Col>
                    <Col span={12} style={{ padding: '8px' }}>
                        <div className="fac-text">
                        TROUWIDS® is an American manufacturer specializing in high-end home appliances, with its global headquarters in Charlotte, North Carolina, USA. TROUWIDS® has built three factories in the United States, Japan and China, and has a highly skilled and innovative R&D team, as well as a high-quality manufacturing and management team.
                        </div>
                        <br/><br/>
                        <div className="fac-text">
                        Our factory has modern production equipment and strict quality management system to ensure that every product we produce conforms to high quality standards. We pay attention to the design, quality, function and service of products, and continue to invest in research and development and innovation to meet the needs and expectations of customers.
                        </div>
                        <br/><br/>
                        <div className="fac-text">
                        Our products cover kitchen appliances, bathroom appliances, household appliances and other fields, including high-end water purifiers, smart homes, smart cameras, smart lights, smart bathroom, smart WIFI, smart air purifiers and so on. Our products adopt high-performance imported materials and production quality control, have humanized design and use experience, and can meet the diversified needs and use habits of customers.
                        </div>
                        <br/><br/>
                        <div className="fac-text">
                        Our manufacturing process and quality management system have obtained multiple international certifications such as ISO 9001, ISO 14001, OHSAS 18001, and our products have also passed multiple international standard certifications such as CE, ROHS, EMC, and CCC, meeting the import requirements of the European and American markets.
                        </div>
                        <br/><br/>
                        <div className="fac-text">
                        We adhere to the business philosophy of "quality first, service first, integrity and common development", take customers as the center, continue to improve our products and services, and create higher value and better user experience for customers.
                        </div>
                    </Col>
                    <Col span={6} style={{ padding: '8px' }}>
                        <Image src={pic3} width={'100%'} preview={false} />
                        <Image src={pic4} width={'100%'} preview={false} />
                    </Col>
                </Row>
            </div>
        )
    }
}