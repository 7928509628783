import React from 'react';

class ContractMobile extends React.Component {
    render() {
        return (
            <div style={{ textAlign: 'center' }}>
                Email : trouwids.usa@gmail.com
            </div>
        )
    }
}

export default ContractMobile;