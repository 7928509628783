import React from 'react';
import './index.less';
import { Image, Grid, Space } from 'antd-mobile';
import productData from './data.js';
import pic1 from './img/category-1.jpg';
import cart_2 from './img/cart_2.png';
class ProductListMobile extends React.Component {
    // 1001：净水器；1002：监控；1003：淋浴
    goMall = (e) => {
        let key = e.target.getAttribute('w_key') || '';
        let url = e.target.getAttribute('w_url') || '';
        if ( url != '') {
            window.open(url);
        } else {
            window.open("https://www.lazada.com.ph/shop/trouwids-purification/?itemId=4734136218&spm=a2o4l.pdp_revamp.seller.1.287164c1XvMBy7&path=promotion-433725-0.htm&tab=promotion&channelSource=pdp");
        }
        // if (key == 1001 || key >= 100000 && key < 200000) {
        //     window.open('https://ph.xiapibuy.com/shop/873499914/category-landing?shopCollection=158983908');
        // } else if (key == 1002 || key >= 200000 && key < 300000) {
        //     window.open('https://ph.xiapibuy.com/shop/873499914/category-landing?itemId=18156948690&shopCollection=246046931&upstream=pdp');
        // }
    }
    render() {
        return (
            <div>
                {productData.map(item => {
                    return (
                        <Grid columns={3}>
                            <Grid.Item span={3}><img src={item.pic} w_key={item.category} onClick={this.goMall}></img></Grid.Item>
                            {item.child.map(child => {
                                return (
                                    <Grid.Item span={1} style={{ padding: '2rem',textAlign: 'center' }}>
                                        <Image src={child.pic} style={{ background:'white' }} />
                                        <button className='buy-btn' w_key={ child.id || '' } onClick={ this.goMall } w_url={ child.url || '' }>
                                            <img src={cart_2} w_key={ child.id || '' } w_url={ child.url || '' } width={20} style={{ float: 'left', marginTop:'-0.2rem' }} onClick={ this.goMall }></img>
                                            <div w_key={ child.id || '' } w_url={ child.url || '' } style={{ float: 'left', marginLeft: '0.4rem' }} onClick={ this.goMall }> >> BUY NOW</div>
                                        </button>
                                    </Grid.Item>
                                )
                            })}
                        </Grid>
                    )
                })}

            </div>
        )
    }
}

export default ProductListMobile;