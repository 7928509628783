import React from 'react';
import './index.less';
import { Image, Grid } from 'antd-mobile';
import pic1 from './img/1.jpg';
import pic2 from './img/2.jpg';
import pic3 from './img/3.jpg';
import pic4 from './img/4.jpg';



class FactoryMobile extends React.Component {
    render () {
        return (
            <div className='fac-mobile'>
                <Grid columns={2} style={{ padding: '2rem' }}>
                    <Grid.Item span={2} className={'desTxt'}>
                    TROUWIDS® is an American manufacturer specializing in high-end home appliances, with itsglobal headquarters in Charlotte, North Carolina, USA. TROUWIDS® has built three factories in theUnited States, Japan and China, and has a highly skilled and innovative R8D team, as well as ahigh-quality manufacturing and management team.
                    <br/><br/>
                    <Image src={pic4} style={{ marginTop: '-2rem' }}/>
                    </Grid.Item>
                    <Grid.Item span={2} className={'desTxt'}>
                    Our factory has modern production equipment and strict quality management system to ensurethat every product we produce conforms to high quality standards. We pay attention to thedesign, quality, function and service of products, and continue to invest in research anodevelopment and innovation to meet the needs and expectations of customers.
                    <br/><br/>
                    </Grid.Item>
                    <Grid.Item span={1} style={{ marginTop: '-2rem' }}>
                        <Image src={pic3} />
                    </Grid.Item>
                    <Grid.Item span={1} style={{ marginTop: '-2rem' }}>
                        <Image src={pic2} />
                    </Grid.Item>
                    <Grid.Item span={2} className={'desTxt'}>
                    Our products cover kitchen appliances, bathroom appliances, household appliances and otherfields, including high-end water purifiers, smart homes, smart cameras, smart lights, smartbathroom, smart WIFl, smart air purifiers and so on. Our products adopt high-performanceimported materials and production guality control, have humanized design and use experienceand can meet the diversified needs and use habits of customers.
                    <br/><br/>
                    </Grid.Item>
                    <Grid.Item span={2} style={{ marginTop: '-2rem' }}>
                        <Image src={pic1} />
                    </Grid.Item>
                    <Grid.Item span={2} className={'desTxt'}>
                    Our manufacturing process and quality management system have obtained multipleinternational certifications such as ISO 9001, ISO 14001, OHSAS 18001, and our products havealso passed multiple international standard certifications such as CE, ROHS, EMC, and CCCmeeting the import requirements of the European and American markets.
                    <br/><br/>
                    We adhere to the business philosophy of "quality first, service first, integrity and commondevelopment", take customers as the center, continue to improve our products and services, andcreate higher value and better user experience for customers.
                    </Grid.Item>
                </Grid>
            </div>
        )
    }
}

export default FactoryMobile;