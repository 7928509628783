import React from "react";
import { Divider, Col, Row, Image, Carousel, Descriptions } from 'antd';
import './index.less';
import pic from './img/6.png';
import pic1 from './img/intro-1.jpg';
import pic2 from './img/intro-2.jpg';
import pic3 from './img/intro-3.jpg';
import pic4 from './img/intro-4.jpg';

export default class Introduce extends React.Component {
    render() {
        return (
            <div className="introduce" id="introduce">
                <Divider children={'Introduction'} className='line-title' />
                <Row>
                    <Col span={12} style={{ padding: '10px' }}>
                        <Carousel autoplay style={{textAlign:'center'}}>
                            <div>
                                <Image src={pic1} preview={false} width={'100%'}/>
                            </div>
                            <div>
                                <Image src={pic2} preview={false} width={'100%'}/>
                            </div>
                            <div>
                                <Image src={pic3} preview={false} width={'100%'}/>
                            </div>
                            <div>
                                <Image src={pic4} preview={false} width={'100%'}/>
                            </div>
                        </Carousel>
                    </Col>
                    <Col span={12} style={{ padding:'10px' }}>
                        <div className={'introTxt'}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;TROUWIDS® is a famous household life brand in the United States, founded in 1959, focusing on the deep 
                        integration of the physical world and the digital world, and applying technology to household life products. It is a high-tech enterprise providing 
                        solutions such as the Internet of Things, water purification, electronic products, smart home, etc. To provide customized products and services for 
                        the improvement of home life around the world, and to bring aviation and industrial high-tech technology into the lives of ordinary families, to realize 
                        the interconnection of everything, and to make the world realize a more intelligent, safe and sustainable family health life. The global headquarters of 
                        TROUWIDS® is located in Charlotte, North Carolina, USA.
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}