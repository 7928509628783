import React from 'react';
import './index.css';
import Header from './header/index.js';
import Introduce from './introduce';
import ProductList from './productList/index.js';
import Factory from './factory';
import Contact from './contact_us/index.js';
import News from './news';

class Home extends React.Component {
    render(){
        return (
            <div className='home'>
                <Header />
                <Introduce />
                {/* <News /> */}
                <ProductList />
                <Factory />
                <Contact />

            </div>
          )
    }
}
export default Home;