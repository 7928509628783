import React from "react";
import { Divider, Row, Col, Badge, Image } from "antd";
import './index.less';

import new1 from './img/1.png';
import new2 from './img/2.png';

export default class News extends React.Component {
    render () {
        return (
            <div className="news" >
                <Divider children={'News Center'} className='line-title' id="news"/>
                <Row>
                    <Col span={6} style={{ paddingLeft: '8px',paddingRight: '8px' }}>
                        <Image src={ new1 }/>
                        <div className="newsTxt">
                        TRUWIDS is a famous household life brand in the United States, founded in 1959, focusingon the deep integration of the physical world and the digital world, and applying technology tonousehold life products. 
                        </div>
                    </Col>
                    <Col span={6}>
                        <Image src={new2} />
                        <div className="newsTxt">
                        TRUWIDS is a famous household life brand in the United States, founded in 1959, focusingon the deep 
                        </div>
                        <Image src={new2} />
                        <div className="newsTxt">
                        TRUWIDS is a famous household life brand in the United States, founded in 1959, focusingon the deep 
                        </div>
                    </Col>
                    <Col span={12} style={{ fontSize: '18px', paddingLeft: '50px' }}>
                        <div style={{ padding: '1px' }}>
                            <Badge count={4} color={'#FF3300'}/> &nbsp;111111111111111111111111111111111111
                        </div>
                        <div style={{ padding: '1px' }}>
                            <Badge count={5} color={'#FF6600'}/> &nbsp;22222222222222222222222222222222222222
                        </div>
                        <div style={{ padding: '1px' }}>
                            <Badge count={6} color={'#FFCC00'}/> &nbsp;333333333333333333333333333333
                        </div>
                        <div style={{ padding: '1px' }}>
                            <Badge count={7} color={'#CCCCCC'}/> &nbsp;444443334444444444444444444444444444
                        </div>
                        <div style={{ padding: '1px' }}>
                            <Badge count={8} color={'#CCCCCC'}/> &nbsp;55555555555555555555555555555555
                        </div>
                        <div style={{ padding: '1px' }}>
                            <Badge count={9} color={'#CCCCCC'}/> &nbsp;666666666666666666666666666666666666
                        </div>
                        <div style={{ padding: '1px' }}>
                            <Badge count={10} color={'#CCCCCC'}/> &nbsp;666666666666666666666666666666666666
                        </div>
                        <div style={{ padding: '1px' }}>
                            <Badge count={11} color={'#CCCCCC'}/> &nbsp;666666666666666666666666666666666666
                        </div>
                        <div style={{ padding: '1px' }}>
                            <Badge count={12} color={'#CCCCCC'}/> &nbsp;666666666666666666666666666666666666
                        </div>
                        <div style={{ padding: '1px' }}>
                            <Badge count={13} color={'#CCCCCC'}/> &nbsp;666666666666666666666666666666666666
                        </div>
                        <div style={{ padding: '1px' }}>
                            <Badge count={14} color={'#CCCCCC'}/> &nbsp;666666666666666666666666666666666666
                        </div>
                        <div style={{ padding: '1px' }}>
                            <Badge count={15} color={'#CCCCCC'}/> &nbsp;666666666666666666666666666666666666
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}