import React from "react";
import qs from 'qs';

import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';

// const { id } = qs.parse( this.props.location.search.slice(1) );
console.log(this);
export default class ProDetail extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        
        return (
            <div className="proDetail">
                <Breadcrumb items={[
                    {
                        title: <Link to={'/'}>Home</Link>,
                    },
                    {
                        title: 'Product Detail',
                    },
                ]} />
                2222
            </div>
        )
    }
}