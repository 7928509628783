import React from 'react';
import './index.less';
import { Divider } from 'antd-mobile';

import MobileHeader from './header/index';
import IntroductionMobile from './introduce/index';
import ProductListMobile from './productList/index';
import ContractMobile from './contract_us/index';
import FactoryMobile from './factory';

class MobileHome extends React.Component {
    render() {
        return (
            <div className='home' style={{ paddingBottom: '5rem' }}>
                <MobileHeader />
                <Divider title='Introduction' style={{ fontSize: '3.5rem', color: 'black', fontWeight: 500, borderColor: '#6c6c6c', }}>
                    <span id="introduction_m">Introduction</span>
                </Divider>
                <IntroductionMobile />
                <Divider title='productList' style={{ fontSize: '3.5rem', color: 'black', fontWeight: 500, borderColor: '#6c6c6c', }}>
                    <span id="productlist_m">ProductList</span>
                </Divider>
                <ProductListMobile />
                <Divider title='Our Factory' style={{ fontSize: '3.5rem', color: 'black', fontWeight: 500, borderColor: '#6c6c6c', }}>
                    <span id="factory_m">Our Factory</span>
                </Divider>
                <FactoryMobile />
                <Divider title='contract' style={{ fontSize: '3.5rem', color: 'black', fontWeight: 500, borderColor: '#6c6c6c', }}>
                    <span id="contact_m">Contact Us</span>
                </Divider>
                <ContractMobile />
            </div>
        )
    }
}
export default MobileHome;