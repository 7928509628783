import React from "react";
import { Swiper, Image, Grid, Space, ConfigProvider } from 'antd-mobile';
import './index.less';
import pic1 from './img/intro-5.jpg';
import pic2 from './img/intro-6.jpg';
import pic3 from './img/intro-7.jpg';
import pic4 from './img/intro-8.jpg';

class IntroductionMobile extends React.Component {
    render() {
        return (
            <div className="introductionMobile">
                <Grid columns={2}>
                    <Grid.Item span={2} className={'desTxt'}>
                    TROUWIDS® is a famous household life brand in the United States, founded in 1959, focusing on the deep integration of the physical world and the digital world, and applying technology to household life products. It is a high-tech enterprise providing solutions such as the Internet of Things, water purification, electronic products, smart home, etc. To provide customized products and services for the improvement of home life around the world, and to bring aviation and industrial high-tech technology into the lives of ordinary families, to realize the interconnection of everything, and to make the world realize a more intelligent, safe and sustainable family health life. The global headquarters of TROUWIDS® is located in Charlotte, North Carolina, USA.
                    </Grid.Item>
                    <Grid.Item span={1}>
                        <Image src={pic1} style={{ padding:'1rem', marginTop:'-1rem' }}/>
                    </Grid.Item>
                    <Grid.Item span={1} style={{ padding:'1rem', marginTop:'-1rem' }}>
                        <Image src={pic3} />
                    </Grid.Item>
                </Grid>
                {/* <Grid columns={2} className={'introPic'}>
                    <Grid.Item span={2} className={'desTxt'}>
                    &nbsp;&nbsp;&nbsp;&nbsp;TRUWIDS is a famous household life brand in the United States, founded in 1959, focusing on the deep
                    </Grid.Item>
                    <Grid.Item span={1} style={{ paddingRight: '1rem', marginTop:'-1.5rem' }}>
                        <Image src={pic1} />
                    </Grid.Item>
                    <Grid.Item span={1} style={{ marginTop: '-2rem' }} className={'desTxt'}> 
                        integration of the physical world and the digital world, and applying technology to household life products. It is a high-tech enterprise providing solutions such as the Internet of Things,water
                    </Grid.Item>
                    <Grid.Item span={2} className={'desTxt'} style={{ marginTop: '-2rem' }}>
                    purification, electronic products, smart home, etc. To provide customized products and services for the
                    </Grid.Item>
                    <Grid.Item span={1} className={'desTxt'} style={{ marginTop: '-2rem' }}>
                     improvement of home life around the world, and to bring aviation and industrial high-tech technology into the lives of ordinary families, home, etc. To provide customized products and services for
                    </Grid.Item>
                    <Grid.Item span={1} style={{ paddingLeft: '2rem' }}>
                        <Image src={pic2} />
                    </Grid.Item>
                    <Grid.Item span={2} className={'desTxt'} style={{ marginTop: '-2rem' }}> 
                    the improvement of home life around the world, and to bring aviation and industrial high-tech technology into
                    </Grid.Item>
                    <Grid.Item span={1} style={{ paddingRight: '1rem',marginTop: '-2rem' }}>
                        <Image src={pic3} />
                    </Grid.Item>
                    <Grid.Item span={1} className={'desTxt'} style={{ marginTop: '-2rem' }}>
                    the lives of ordinary families, to realize the interconnection of everything, and to make the world realize a more intelligent, safe and sustainable family health
                    </Grid.Item>
                    <Grid.Item span={2} className={'desTxt2'} style={{ marginTop:'-2rem' }}>
                        life. The global headquarters of TROUWIDS is located in Charlotte, North Carolina, USA.
                    </Grid.Item>
                </Grid> */}
            </div>
        )
    }
}

export default IntroductionMobile;