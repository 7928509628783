import React, { useRef } from 'react';
import './index.less';
import pic1 from './img/h_4.jpg';
import pic2 from './img/h_5.jpg';
import pic3 from './img/h_6.jpg';
import pic_logo from './img/3.png';

import { Button, Space, Swiper, Tabs, Image, Grid, Badge } from 'antd-mobile';
import { SwiperRef } from 'antd-mobile/es/components/swiper';
import styles from './index.less';

const colors = ['#ace0ff', '#bcffbd', '#e4fabd', '#ffcfac']
class MobileHeader extends React.Component {
    onChange = (anchor) => {
        if (anchor) {
            let anchorElement = document.getElementById(anchor);
            if (anchorElement) {
              anchorElement.scrollIntoView({block:'start', behavior: 'smooth'});
            }
          }
    }
    render() {
        return (
            <div className='header'>
                <Grid columns={5}>
                    <Grid.Item span={1} className={'header-left'}>
                        {/* <Image src={pic_logo} width={'16rem'}/> */}
                        EXSEAPAX®
                    </Grid.Item>
                    <Grid.Item span={4}>
                        <Grid columns={3} style={{ paddingTop: '1rem' }}>
                            <Grid.Item span={2} style={{ paddingLeft: '2rem', paddingTop: '0.3rem' }}>
                                {/* <Badge content={'FOLLOWER : 18.9 Million'} style={{ fontSize: '3rem' }}></Badge> */}
                                <div className='count-num'>FOLLER : 78.2 Million</div>
                            </Grid.Item>
                            <Grid.Item span={1}>
                                <Button color={'primary'} style={{ marginRight: '1rem', fontSize: '1.5rem' }}>Login</Button>
                                <Button style={{ fontSize: '1.5rem' }}>Sign Up</Button>
                            </Grid.Item>
                        </Grid>
                        <Grid columns={1}>
                            <Grid.Item style={{ paddingLeft: '1rem', paddingTop: '1rem' }}>
                                <Tabs onChange={this.onChange}>
                                    <Tabs.Tab title='Introduction' key='introduction_m'/>
                                    <Tabs.Tab title='Product List' key='productlist_m' />
                                    <Tabs.Tab title='Our Factory' key='factory_m' />
                                    <Tabs.Tab title='Contact Us' key='contact_m' />
                                </Tabs>
                            </Grid.Item>
                        </Grid>
                    </Grid.Item>
                    {/* <Grid.Item span={1}>
                        <Badge content={'FOLLOWER : 18.9 Million'}></Badge>
                    </Grid.Item>
                    <Grid.Item span={1} style={{ float: 'right' }}>
                        <Button color={'primary'} style={{ marginRight: '1rem' }}>Login</Button>
                        <Button>Sign Up</Button>
                    </Grid.Item> */}
                </Grid>
                <Swiper autoplay loop>
                    <Swiper.Item key={'11'}>
                        <Image src={pic1} />
                    </Swiper.Item>
                    <Swiper.Item key={'22'}>
                        <Image src={pic2} />
                    </Swiper.Item>
                    <Swiper.Item key={'33'}>
                        <Image src={pic3} />
                    </Swiper.Item>
                </Swiper>
            </div>
        )
    }
}
export default MobileHeader;