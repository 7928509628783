import React, { useEffect } from 'react';
import MenuList from './menulist.js';
import { Divider, Col, Row, Image, Carousel, Statistic, Badge, Button  } from 'antd';
import { LikeOutlined } from '@ant-design/icons';

import './index.less';
import logo from './img/trouwids.png';
import logo4 from './img/4.png';

import pic1 from './img/h_1.jpg';
import pic2 from './img/h_2.jpg';
import pic3 from './img/h_3.jpg';
import cart from './img/cart.png';

export default class Header extends React.Component {

    goMall = () => {
        // window.open('https://ph.xiapibuy.com/trouwids.ph?categoryId=100010&entryPoint=ShopByPDP&itemId=18156948690');
        window.open('https://www.lazada.com.ph/shop/trouwids-purification/?itemId=4734136218&spm=a2o4l.pdp_revamp.seller.1.287164c1XvMBy7&path=promotion-433725-0.htm&tab=promotion&channelSource=pdp');
    }
    dateRam = () => {
        return ( (Date.now()/100000+88900000 )/1000000 ).toFixed(2);
    }
    render() {
        return (
            <div className='topHome' data-translatable>
                <div className='header'>
                    <div className='left'>
                        <div className='logoTilte' >TROUWIDS®</div>
                    </div>
                    <div className='menu'>
                        <MenuList />
                    </div>
                    <div className='right'>
                        {/* <img src={logo4} className='rightLogo' /> */}
                        {/* FOLLWER：{follower} */}
                        {/* <Statistic title="FOLLOWER" value={'18.5 Million'} prefix={''} style={{ marginTop: '30px' }}/> */}
                        
                        <div style={{ float: 'left', textAlign: 'center', paddingTop: '48px' }} >
                        <Image src={cart} width={30} style={{ marginTop: '-10px', cursor: 'pointer' }} preview={false} onClick={ this.goMall}/> <span className='arrow' onClick={ this.goMall}>>> Go to the store now</span>
                        </div>
                        <Badge count={`FOLLOWER : ${this.dateRam()} Million`} style={{ marginTop: '0px', fontSize:'16px', marginLeft: '8px'}} />
                        <Button type='primary' style={{ marginTop: '45px', marginLeft:'10px'}} >Login</Button>
                        <Button style={{ marginTop: '45px', marginLeft:'2px'}} >Sign Up</Button>
                    </div>
                </div>
                <div className='middle'>
                    <Row>
                        <Col span={24} style={{ padding: 10 }}>
                            <Carousel autoplay style={{ textAlign: 'center' }}>
                                <div>
                                    <Image src={pic1} preview={false} width={'100%'} />
                                </div>
                                <div>
                                    <Image src={pic2} preview={false} width={'100%'} />
                                </div>
                                <div>
                                    <Image src={pic3} preview={false} width={'100%'} />
                                </div>
                            </Carousel>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}