import React from 'react';
import './index.css';
import { Divider } from 'antd';

export default class Contact extends React.Component {
    render() {
        return (
            <div className='contact' id='contact'>
                <Divider children={'Contact Us'} className='line-title' />
                <div>Email : trouwids.usa@gmail.com</div>
            </div>
        )
    }
}