import './App.css';
import { ConfigProvider } from 'antd-mobile';
import enUS from 'antd-mobile/es/locales/en-US';
import {Link,Router, Route, Routes} from 'react-router-dom';

import Home from './Home/index.js';
import MobileHome from './Mobile/index.js';
import ProDetail from './Home/productList/detail';


function IsMobile() {
  let plat = navigator.userAgent.match( // 判断不同端
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  );
  return plat ? true : false;

}

function App() {
  if (IsMobile()) {
    return (<ConfigProvider locale={enUS}><MobileHome /></ConfigProvider>)
  } else {
    return (
      <Home />
      // <Routes>
      //   <Route path='/' element={<Home />} />
      //   <Route path='/proDetail' element={ <ProDetail /> } />
      // </Routes>
    )
  }
}

export default App;
